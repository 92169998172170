<template>
  <section class="cookie-bar" @click="cookie_consent()">
    {{ (translations || {}).cookie_message }}
    <button class="button button--green">{{ (translations || {}).cookie_consent_button }}</button>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { event_hub } from "../../../../main"
import { CLIENT_STORE } from "../../constants/other";

export default {
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations"
    ])
  },
  methods: {
    cookie_consent() {
      localStorage.setItem("user_consents_to_GDPR", true);
      event_hub.$emit("user_agreed_to_GDPR");
    }
  },
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px 20px;
  background-color: var(--background_heavy);
  color: $pure-white;
  text-align: left;
  box-sizing: border-box;

  .button {
    height: 30px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 30px;
    box-sizing: border-box;
  }
}
</style>
